import type {
  BillingItemType,
  ParsedBillingItem,
  ParsedCartItem,
} from 'ui/types/cartTypes';
import {
  ANNUAL_MEMBERSHIP_KEY,
  TWENTYFOUR_MONTH_MEMBERSHIP_KEY,
} from 'ui/tests/fixtures/ecommProductFixtures';
import type { Product } from 'services/generated/commerce-service';
import {
  MAX_FAMILY_SIZE,
  getPriceToAddOneFamilyMember,
} from 'lib/utils/familyHelpers';
import type {
  Language,
  Region,
  JFCurrency,
} from '@whoop/i18n/types/internationalization';
import { i18n } from '@whoop/i18n/lang/client';
import { formatDisplayPrice as formatDisplayPriceWithSiteValues } from 'lib/utils/formatHelpers';

export function isMembershipAndDeviceInCartItems(
  cartItems: ParsedCartItem[],
): boolean {
  return (
    cartItems.some((item) => item.productType === 'device') &&
    cartItems.some((item) => item.productType === 'membership')
  );
}

export function getMembershipInCart(
  cartItems: ParsedCartItem[],
): ParsedCartItem | undefined {
  return cartItems.find((item) => item.productType === 'membership');
}

export function cartHasPrepaidMembership(cartItems: ParsedCartItem[]): boolean {
  return cartItems.some((item) =>
    [ANNUAL_MEMBERSHIP_KEY, TWENTYFOUR_MONTH_MEMBERSHIP_KEY].includes(item.key),
  );
}

export const getCartItemPriceValues = (item: ParsedCartItem) => {
  const isDiscounted = Boolean(item.discountedPrice);
  const originalPrice = item.originalPrice.centAmount;
  let displayPrice;
  if (isDiscounted) {
    displayPrice = item.discountedPrice?.value.centAmount;
  } else {
    displayPrice = originalPrice;
  }
  return { displayPrice, originalPrice, isDiscounted };
};

export function getCartBillingItemAmount(
  billingItems: ParsedBillingItem[],
  type: BillingItemType,
): number | undefined {
  return billingItems.find((detail) => detail.type === type)?.value?.centAmount;
}

export const getPrepaidMembershipInCart = (
  cartItems: ParsedCartItem[],
): ParsedCartItem | undefined => {
  return cartItems.find((item) =>
    [ANNUAL_MEMBERSHIP_KEY, TWENTYFOUR_MONTH_MEMBERSHIP_KEY].includes(item.key),
  );
};

export const getPrepaidMembershipFamilyRanges = (
  cartItems: ParsedCartItem[],
) => {
  const prepaidMembership = getPrepaidMembershipInCart(cartItems);
  const prepaidMembershipQty = prepaidMembership?.quantity || 0;
  const hasOnePrepaidMembershipInCart = prepaidMembershipQty === 1;
  const hasMultipleInRangePrepaidMembershipsInCart =
    prepaidMembershipQty > 1 && prepaidMembershipQty <= MAX_FAMILY_SIZE;
  const hasPrepaidMembershipsInRange =
    hasOnePrepaidMembershipInCart || hasMultipleInRangePrepaidMembershipsInCart;
  return {
    hasPrepaidMembershipsInRange,
    hasOnePrepaidMembershipInCart,
    hasMultipleInRangePrepaidMembershipsInCart,
  };
};

export const getPreferredFamilyPlanUpsellInfo = ({
  cartItems,
  annualMembershipProduct,
  familyMembershipProduct,
  isRafGiftFlow,
  asAGift,
  currentPage,
  currency,
  language,
  region,
}: {
  cartItems: ParsedCartItem[];
  annualMembershipProduct?: Product;
  familyMembershipProduct?: Product;
  isRafGiftFlow?: boolean;
  asAGift: boolean;
  currentPage: string;
  currency: JFCurrency;
  language: Language;
  region: Region;
}): {
  displayCTACard: boolean;
  title: string;
  subtitle: string;
} => {
  let title = '',
    subtitle = '';

  // Calculate and format price to add one family member
  const priceToAddOneFamilyMember = getPriceToAddOneFamilyMember({
    displayMembershipProduct: familyMembershipProduct,
    annualMembershipProduct,
  });
  const formattedPriceToAddOneFamilyMember = formatDisplayPriceWithSiteValues(
    priceToAddOneFamilyMember || 0,
    { currency, language, region },
    { roundUp: true },
  );

  const {
    hasPrepaidMembershipsInRange,
    hasOnePrepaidMembershipInCart,
    hasMultipleInRangePrepaidMembershipsInCart,
  } = getPrepaidMembershipFamilyRanges(cartItems);

  // Determine if the CTA card should be displayed
  const displayCTACard = Boolean(
    familyMembershipProduct &&
      !isRafGiftFlow &&
      !asAGift &&
      currentPage !== 'Family' &&
      hasPrepaidMembershipsInRange,
  );

  // Set and return displayCTACard, title, and subtitle
  if (!displayCTACard) return { displayCTACard, title, subtitle };
  if (hasOnePrepaidMembershipInCart) {
    title = i18n.t('cart:includeYourFamily');
    subtitle = i18n.t('cart:saveBigFamilyMembership', {
      price: formattedPriceToAddOneFamilyMember,
    });
  } else if (hasMultipleInRangePrepaidMembershipsInCart) {
    title = i18n.t('cart:buyingMoreThanOne');
    subtitle = i18n.t('cart:saveBigFamilyMoreThanOne');
  }

  return { displayCTACard, title, subtitle };
};
