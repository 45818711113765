'use client';

import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import type { ItemContext, PurchaseContext } from 'whoop-analytics';
import analytics from 'whoop-analytics';
import { getReferralType } from 'whoop-analytics/utils';
import type {
  AnalyticsContext,
  ImpactCategories,
  SegmentEvents,
} from 'whoop-analytics/lib/types';
import { AmplitudeEvents, PAGE_VIEWS } from 'whoop-analytics/lib/types';
import type {
  CartProduct,
  OrderTotalType,
  PlacedOrder,
  MembershipProduct,
  AnalyticsPageTitle,
  CartItemType,
} from 'ui';
import { findOrderTotal, getCurrentPageFromURL } from 'ui';
import { useShallow } from 'zustand/react/shallow';
import { useState } from 'react';
import type { CountryCode } from '@whoop/i18n/types/address';
import type { CheckoutAnalytics } from 'services/generated/commerce-service';
import { isRestOfWorld } from '@whoop/i18n/utils/i18nHelpers';
import type {
  ParsedBillingItem,
  ParsedCartDTO,
  ParsedCartItem,
} from 'ui/types/cartTypes';
import {
  ANNUAL_MEMBERSHIP_KEY,
  TRIAL_MEMBERSHIP_KEY,
} from 'ui/tests/fixtures/ecommProductFixtures';
import { getCookie } from 'cookies-next';
import {
  cartHasPrepaidMembership,
  getCartBillingItemAmount,
  getMembershipInCart,
} from '@/components/Cart/cart.utils';
import { useSiteStore } from '@/store/SiteStoreProvider';
import { env } from 'env';
import {
  getAccessoryContextV2,
  getAnalyticsAmount,
} from 'lib/utils/analyticsHelper';

interface IAnalyticsTotals {
  subtotal: number;
  shipping: number;
  tax: number;
  total: number;
}

interface MembershipContext {
  membership?: number;
  membership_quantity?: number;
  is_trial?: boolean;
}

interface GetPurchaseOrderProps {
  cartId: string;
  cartItems: ParsedCartItem[];
  billingItems: ParsedBillingItem[];
  userId?: number;
  showCents: boolean;
}

interface PlacedOrderContext extends MembershipContext {
  order_id: string;
  currency: Currency;
  flow_type: string;
  subtotal_amount: number;
  shipping: number;
  checkout_amount: number;
  total_accessory_count: number;
  accessory_total: number;
  address_country?: string;
}

interface AmplitudeEventProps extends MembershipContext {
  referral_type?: string;
  currency: Currency;
  language: Language;
  region: Region;
  country: CountryCode;
  promoCode: string | undefined;
  site: string;
  purchase_data?: PurchaseContext;
  flow_type?: string;
  total_accessory_count?: number;
  unique_accessory_count?: number;
  partnership?: string;
  page: string;
  previous_page?: string;
}

const paths: AnalyticsPageTitle[] = ['Initial'];

/**
 * TODO: A lot of these analytics functions should be plain javascript and should be React agnostic.
 * We should be able to use analytics outside of React components by default.
 * Then re-export them in a useAnalytics hook for React components to use.
 */

export function useAnalytics() {
  const [isInitialized, setIsInitialized] = useState(false);
  const {
    asAGift,
    isRafGiftFlow,
    currency,
    language,
    promoInfo,
    promoContent,
    region,
    country,
    shippingAddress,
    cart,
  } = useSiteStore(
    useShallow((state) => ({
      asAGift: state.asAGift,
      isRafGiftFlow: state.isRafGiftFlow,
      currency: state.currency,
      language: state.language,
      promoInfo: state.promoInfo,
      promoContent: state.promoContent,
      region: state.region,
      country: state.country,
      shippingAddress: state.shippingAddress,
      cart: state.cart,
    })),
  );

  const initialize = (): void => {
    if (!isInitialized) {
      analytics.initialize({
        amplitude: {
          key: env.NEXT_PUBLIC_AMPLITUDE_API_KEY,
          options: {
            defaultTracking: {
              attribution: true,
              pageViews: {
                trackOn: 'attribution',
              },
              sessions: false,
              fileDownload: false,
              formInteraction: false,
            },
            cookieOptions: {
              domain: '.whoop.com',
            },
            includeReferrer: true,
            includeUtm: true,
          },
        },
        gtm: {
          id: env.NEXT_PUBLIC_GTM_ID,
        },
        segment: {
          key: env.NEXT_PUBLIC_SEGMENT_API_KEY,
        },
        datadog: {
          applicationId: env.NEXT_PUBLIC_DD_APP_ID,
          clientToken: env.NEXT_PUBLIC_DD_CLIENT_TOKEN,
          service: 'join-flow',
          env: env.NEXT_PUBLIC_ENV,
          enableSessionReplay: true,
        },
        sentry: {
          env: env.NEXT_PUBLIC_ENV,
          dsn: env.NEXT_PUBLIC_SENTRY_DSN,
        },
      });
      setAmplitudeUserProperty('join_flow_language', language);
      if (isRestOfWorld(currency, region)) {
        setAmplitudeUserProperty('row_country', region);
      }
      setIsInitialized(true);
    }
  };

  const trackPageView = (pageName: string): void => {
    analytics.trackPageView({
      page_location: window.location.href.split('?')[0],
      language,
      page_title: pageName,
    });
  };

  const getMembershipCategory = (cartItem: CartItemType): ImpactCategories => {
    if (cartItem.product_type === 'membership') {
      const item = cartItem as MembershipProduct;
      if (item.membership_type === 'prepaid') {
        return item.trial_months === 12 ? '12-month' : '24-month';
      }
      return '';
    }
    return '';
  };

  const getMembershipCategoryV2 = (
    cartItem: ParsedCartItem,
  ): ImpactCategories => {
    if (cartHasPrepaidMembership([cartItem])) {
      return cartItem.key === ANNUAL_MEMBERSHIP_KEY ? '12-month' : '24-month';
    }
    return '';
  };

  const getItemContextV2 = (
    cartItem: ParsedCartItem,
    showCents: boolean,
  ): ItemContext => {
    const price = showCents
      ? getAnalyticsAmount(
          cartItem.discountedPrice?.value.centAmount ??
            cartItem.originalPrice.centAmount,
        )
      : cartItem.discountedPrice?.value.centAmount ??
        cartItem.originalPrice.centAmount;
    return {
      item_id: cartItem.sku,
      item_name: cartItem.title,
      item_variant: cartItem.description.join(),
      price,
      quantity: cartItem.quantity,
      category: getMembershipCategoryV2(cartItem),
    };
  };

  const trackIrePurchaseV2 = (
    cartId: string,
    cartItems: ParsedCartItem[],
    billingItems: ParsedBillingItem[],
    userId?: number,
  ): void => {
    const analyticsTotals = getPurchaseOrderObjectFromCart({
      cartId,
      cartItems,
      billingItems,
      userId,
      showCents: false,
    });
    // https://whoopinc.atlassian.net/browse/GRO-2220
    // Track conversion through Impact pixel - identify calls in GTM
    if (analyticsTotals.variables && window.ire !== undefined) {
      window.ire(
        'trackConversion',
        analyticsTotals.variables.orderType === 'trial' ? 43730 : 36994,
        analyticsTotals.variables.joinOrder,
        {
          verifySiteDefinitionMatch: true,
        },
      );
    }
  };

  const trackPurchaseV2 = (
    cartId: string,
    cartItems: ParsedCartItem[],
    billingItems: ParsedBillingItem[],
  ): void => {
    const purchaseContext = getPurchaseOrderObjectFromCart({
      cartId,
      cartItems,
      billingItems,
      showCents: true,
    });
    analytics.trackPurchase(purchaseContext);
  };

  const trackMembershipSelect = (membership: MembershipProduct): void => {
    // push membership data to the GTM data layer for Pixel tracking
    window.dataLayer.push({ membership });
  };

  const getTotalsAmount = (
    placedOrder: PlacedOrder,
    totalType: OrderTotalType,
  ): number => {
    if (placedOrder.totals.length === 0) return 0;
    const amount = findOrderTotal(placedOrder.totals, totalType)
      ?.display_amount;
    if (!amount) return 0;
    return getAnalyticsAmount(amount);
  };

  const getAnalyticsTotalsFromBillingItems = (
    billingItems: ParsedBillingItem[],
  ): IAnalyticsTotals => {
    if (billingItems.length === 0) {
      return {
        subtotal: 0,
        shipping: 0,
        tax: 0,
        total: 0,
      };
    }
    return {
      subtotal: getAnalyticsAmount(
        getCartBillingItemAmount(billingItems, 'SUBTOTAL') ?? 0,
      ),
      shipping: getAnalyticsAmount(
        getCartBillingItemAmount(billingItems, 'SHIPPING') ?? 0,
      ),
      tax: getAnalyticsAmount(
        getCartBillingItemAmount(billingItems, 'TAX') ?? 0,
      ),
      total: getAnalyticsAmount(
        getCartBillingItemAmount(billingItems, 'TOTAL') ?? 0,
      ),
    };
  };

  const getPurchaseOrderObjectFromCart = ({
    cartId,
    cartItems,
    billingItems,
    userId,
    showCents,
  }: GetPurchaseOrderProps): PurchaseContext => {
    const analyticsTotals = getAnalyticsTotalsFromBillingItems(billingItems);
    const lineItems = cartItems.map((cartItem) =>
      getItemContextV2(cartItem, showCents),
    );
    return {
      currency,
      transaction_id: cartId,
      value: analyticsTotals.total,
      coupon: promoInfo?.promo_code.code,
      shipping: analyticsTotals.shipping,
      tax: analyticsTotals.tax,
      items: lineItems,
      // https://whoopinc.atlassian.net/browse/GRO-2220
      variables: {
        orderType:
          getMembershipInCart(cartItems)?.key === TRIAL_MEMBERSHIP_KEY
            ? 'trial'
            : 'prepaid',
        joinOrder: {
          orderId: cartId,
          customerId: userId ?? null, // Don't want to send undefined
          currencyCode: currency,
          orderPromoCode: promoInfo?.promo_code.code ?? '',
          orderDiscount:
            getCartBillingItemAmount(billingItems, 'DISCOUNT') ?? 0,
          Customercountry: region,
          // Impact pixel expects same data in different format
          // This gets picked up as a custom variable on the GTM event
          items: lineItems.map((item) => ({
            subTotal: item.price,
            category: item.category,
            sku: item.item_id,
            quantity: item.quantity,
            name: item.item_name,
          })),
        },
      },
    };
  };

  const getMembershipContextV2 = (
    cartItems: ParsedCartItem[],
  ): MembershipContext => {
    const membership = getMembershipInCart(cartItems);
    if (!membership) return {};
    return {
      membership_quantity: membership.quantity,
      is_trial: membership.key === TRIAL_MEMBERSHIP_KEY,
    };
  };

  const getPlacedOrderContextFromCart = (
    cartId: string,
    cartItems: ParsedCartItem[],
    billingItems: ParsedBillingItem[],
  ): PlacedOrderContext => {
    const { membership_quantity, is_trial } = getMembershipContextV2(cartItems);
    const { subtotal, shipping, total } =
      getAnalyticsTotalsFromBillingItems(billingItems);
    const { accessoryCount, accessoryTotal } = getAccessoryContextV2(cartItems);
    return {
      order_id: cartId,
      currency,
      membership_quantity,
      is_trial,
      total_accessory_count: accessoryCount,
      accessory_total: accessoryTotal,
      checkout_amount: total,
      flow_type: asAGift ? 'gift' : 'join',
      shipping,
      subtotal_amount: subtotal,
      address_country: shippingAddress?.country,
    };
  };

  const trackAmplitudeEvent = (
    eventName: AmplitudeEvents,
    options?: AnalyticsContext,
  ): void => {
    const referralType = getReferralType(promoInfo);
    const { membership_quantity, is_trial } = getMembershipContextV2(
      cart.cartItems,
    );
    // eslint-disable-next-line prefer-const
    let { accessories, accessoryCount, uniqueAccessories } =
      getAccessoryContextV2(cart.cartItems);
    const page = getCurrentPageFromURL();
    if (PAGE_VIEWS.includes(eventName) && page !== paths[paths.length - 1])
      paths.push(page);
    const previousPage =
      page === paths[paths.length - 1]
        ? paths[paths.length - 2]
        : paths[paths.length - 1];

    /**
     * TODO: Remove if block
     * This is a temporary work around to fix out of sync issues when adding and removing accessories from the cart on the accessories page.
     */
    if (page === 'Accessories') {
      const sku = options?.sku;
      const accessory = accessories.find((accessory) => accessory.sku === sku);
      if (eventName === AmplitudeEvents.AddedItemToCart) {
        if (!accessory) {
          uniqueAccessories++;
        }
        accessoryCount++;
      }
      if (eventName === AmplitudeEvents.RemovedItemFromCart) {
        if (accessory?.quantity === 1) {
          uniqueAccessories--;
        }
        accessoryCount--;
      }
    }

    const eventProperties: AmplitudeEventProps = {
      membership_quantity,
      is_trial,
      total_accessory_count: accessoryCount,
      unique_accessory_count: uniqueAccessories,
      flow_type: asAGift ? 'gift' : 'join',
      referral_type: isRafGiftFlow ? 'gift_authenticated' : referralType,
      partnership: promoContent?.partnership_name,
      currency,
      language,
      region,
      country,
      promoCode: promoInfo?.promo_code.code,
      site: 'join-ecomm',
      page,
      previous_page: previousPage,
    };

    analytics.trackAmplitudeEvent(eventName, {
      ...options,
      ...eventProperties,
    });
  };

  const trackPlacedOrderV2 = (
    cart: ParsedCartDTO,
    context: AnalyticsContext,
  ) => {
    const { id, cartItems, billingDetails } = cart;
    const placedOrderContext = {
      ...getPlacedOrderContextFromCart(id || '', cartItems, billingDetails),
      ...context,
    };
    trackAmplitudeEvent(AmplitudeEvents.PlacedOrder, placedOrderContext);
    trackPurchaseV2(id || '', cartItems, billingDetails);
  };

  const setAmplitudeUserProperty = (key: string, value: string): void => {
    analytics.setAmplitudeUserProperty(key, value);
  };

  const unsetAmplitudeUserProperty = (key: string): void => {
    analytics.unsetAmplitudeUserProperty(key);
  };

  const identifySegmentUser = (userId: number): void => {
    if (!analytics.identify) return;
    analytics.identify({
      id: userId.toString(),
    });
  };

  const trackSegmentEvent = (
    eventName: SegmentEvents,
    context: AnalyticsContext,
  ): void => {
    analytics.trackSegmentEvent(eventName, context);
  };

  const getCheckoutAnalytics = ({
    experimentVariants,
  }: {
    experimentVariants: Record<string, string | null>;
  }): CheckoutAnalytics => {
    const filteredExperimentVariants: Record<string, string> =
      Object.fromEntries(
        Object.entries(experimentVariants).filter(
          ([key, value]) => value !== null,
        ),
      ) as Record<string, string>;

    return {
      source: getCookie('utm_source') ?? null,
      campaign: getCookie('utm_campaign') ?? null,
      medium: getCookie('utm_medium') ?? null,
      content: getCookie('utm_content') ?? null,
      device_id: analytics?.getDeviceId?.() || null,
      session_id: analytics?.getSessionId?.() || null,
      experiment_variants: filteredExperimentVariants,
      category: 'ecomm web JF',
    };
  };

  return {
    initialize,
    trackPageView,
    trackIrePurchaseV2,
    identifySegmentUser,
    trackSegmentEvent,
    trackAmplitudeEvent,
    setAmplitudeUserProperty,
    unsetAmplitudeUserProperty,
    trackError: analytics.trackError,
    isInitialized,
    trackMembershipSelect,
    getCheckoutAnalytics,
    getDeviceId: analytics.getDeviceId,
    getSessionId: analytics.getSessionId,
    trackPlacedOrderV2,
  };
}
