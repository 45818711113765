import type {
  AccessoryCategory,
  CatalogResponse,
  Product,
} from 'services/generated/commerce-service';

export const GEN_4_DEVICE_SKU = '970-001-000';
export const GEN_4_DEVICE_KEY = 'gen4-black-box';
export const ANNUAL_MEMBERSHIP_SKU = '940-000003-312';
export const ANNUAL_MEMBERSHIP_KEY = 'whoop-membership-12m';
export const FAMILY_MEMBERSHIP_KEY = 'family-membership';
export const TWENTYFOUR_MONTH_MEMBERSHIP_SKU = '940-000003-124';
export const TWENTYFOUR_MONTH_MEMBERSHIP_KEY = 'whoop-membership-24m';
export const MONTHLY_MEMBERSHIP_SKU = '940-000003-121';
export const MONTHLY_MEMBERSHIP_KEY = 'whoop-membership-mtm';
export const TRIAL_MEMBERSHIP_SKU = '940-000003-120';
export const TRIAL_MEMBERSHIP_KEY = 'whoop-membership-1m-trial';
export const ENGRAVING_SKU = '940-000010-010';
export const ENGRAVING_KEY = 'engraving';

export const FAMILY_MEMBERSHIP_SIZE_2_SKU = '2-Person-Family-Subscription';
export const FAMILY_MEMBERSHIP_SIZE_3_SKU = '3-Person-Family-Subscription';
export const FAMILY_MEMBERSHIP_SIZE_4_SKU = '4-Person-Family-Subscription';
export const FAMILY_MEMBERSHIP_SIZE_5_SKU = '5-Person-Family-Subscription';
export const FAMILY_MEMBERSHIP_SIZE_6_SKU = '6-Person-Family-Subscription';

export const FAMILY_MEMBERSHIP_SKUS = [
  FAMILY_MEMBERSHIP_SIZE_2_SKU,
  FAMILY_MEMBERSHIP_SIZE_3_SKU,
  FAMILY_MEMBERSHIP_SIZE_4_SKU,
  FAMILY_MEMBERSHIP_SIZE_5_SKU,
  FAMILY_MEMBERSHIP_SIZE_6_SKU,
];

export const GEN4_DEVICE: Product = {
  product_id: 'f223050d-83ef-4eb8-a7e9-b3e4b10c0f29',
  name: 'WHOOP 4.0 Device',
  description: 'A WHOOP 4.0 device black box without an engraving',
  product_type: 'device',
  key: 'device',
  variants: [
    {
      variant_id: 1,
      sku: '970-001-000',
      price: {
        price_id: '15bf0767-ff0e-4b1e-ad69-5f504f1a32b7',
        value: {
          cent_amount: 0,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-BANDs-800x800-SuperKnit-Onyx.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/onyx_64x64.png',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [],
    },
  ],
};

export const HEATHER_SUPERKNIT_PRODUCT: Product = {
  product_id: '928ac34c-075a-4e3e-941f-202fda9ab6aa',
  name: 'Heather Superknit Band',
  description:
    'When it comes to any performance or profession, nothing should stand in the way of unlocking your potential. It’s why we designed our premium SuperKnit band that improves upon our best-selling ProKnit material. Engineered with an ultra-soft backing and microfilament yarn for enhanced comfort and durability, so you can stay in the zone with zero distractions.Includes Band, Hook, Clasp, and Fast Link slider that makes swapping bands easier than ever. Only compatible with WHOOP 4.0.',
  product_type: 'wrist-band',
  key: 'heather-superknit-band',
  variants: [
    {
      variant_id: 1,
      sku: '955-01-000-000009',
      price: {
        price_id: '9d517a9d-7687-409b-b882-1e50626b641a',
        value: {
          cent_amount: 4900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/heather_64x64.png',
          label: 'swatch',
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-BANDs-800x800-SuperKnit-Heather.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/GEN-4-Shopify-BANDS-View2_600x400-SuperKnit-Heather_34237348-87e2-4b24-96e4-b2c28ed33478.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-BANDS-View3_600x400-SuperKnit-Heather_e8cf89a8-f86b-4796-afd5-4111aa5fc720.png',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Heather',
        },
        {
          name: 'tax_code',
          value: 'PC040200',
        },
        {
          name: 'active',
          value: true,
        },
      ],
    },
  ],
};

export const OOS_HEATHER_SUPERKNIT_PRODUCT: Product = {
  ...HEATHER_SUPERKNIT_PRODUCT,
  variants: [{ ...HEATHER_SUPERKNIT_PRODUCT.variants[0], in_stock: false }],
};

export const CITRA_SUPERKNIT_PRODUCT: Product = {
  product_id: '01e8a097-4a1c-4a13-84da-21a9c21c220f',
  name: 'Citra Superknit Band',
  description: null,
  product_type: 'wrist-band',
  key: 'citra-superknit-band',
  variants: [
    {
      variant_id: 1,
      sku: '955-01-000-000010',
      price: {
        price_id: '66df9186-a9f9-46a1-9a1e-c1bc1493d8be',
        value: {
          cent_amount: 3900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-BANDs-800x800-SuperKnit-Athletic-Brewing-Company-Citranewest.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/GEN-4-Shopify-BANDS-View2_600x400-SuperKnit-ABC-Citra.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-BANDS-View3_600x400-SuperKnit-ABC-Citra_1.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/citra_64x64.png',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Citra',
        },
        {
          name: 'tax_code',
          value: 'PC040200',
        },
        {
          name: 'active',
          value: true,
        },
      ],
    },
  ],
};

export const MOCK_BATTERY_PRODUCT: Product = {
  product_id: '4b72152a-d04f-4093-b17d-fb14314539d6',
  name: 'Battery Pack 4.0',
  description:
    'You’ve got goals and so do we—like keeping you charged wherever your day takes you. Get continuous data for days on a single charge with our waterproof* battery pack that enables you to charge on the go while you wear it. To charge your WHOOP® 4.0, simply slide the battery pack on your device to charge on the go. Use the included USB cable to recharge your battery pack from a laptop. *IP68 dust-proof and water-resistant up to 1 meter for 2 hours.',
  product_type: 'battery',
  key: 'battery-pack',
  variants: [
    {
      variant_id: 1,
      sku: 'battery-4-sku',
      price: {
        price_id: '6c149c8d-3aa7-4f0b-9139-3c5c060df672',
        value: {
          cent_amount: 4900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/GEN-4-Shopify-Battery-View1_600x400_ecebc8da-d749-4e62-aae3-5ddda479f874.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/GEN-4-Shopify-Battery-View2_600x400_4f5228e6-82ee-470d-b88e-f8bbc44ee876.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-Battery-View3_800x1000_9592e051-337c-4023-be54-5398fe395848.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-Battery-View4_600x40_1.png',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'active',
          value: true,
        },
        {
          name: 'tax_code',
          value: 'AFDF',
        },
      ],
    },
  ],
};

export const ONYX_BICEP_BAND: Product = {
  product_id: '9c694b53-5c7f-459c-822c-b45fcc374dbb',
  name: 'Onyx SuperKnit Bicep Band',
  description:
    'When it comes to any performance or profession, nothing should stand in the way of unlocking your potential. It’s why we designed our premium SuperKnit Bicep Band that frees your wrist and allows you to capture data on your upper arm. Engineered with an ultra-soft backing and microfilament yarn for enhanced comfort and durability—so you can stay in the zone with zero distractions. Includes Band, Hook, Clasp, and Fast Link™ slider that makes swapping bands easier than ever. Only compatible with WHOOP® 4.0.',
  product_type: 'bicep-band',
  key: 'onyx-superknit-bicep-band',
  variants: [
    {
      variant_id: 1,
      sku: '956-01-000-000002',
      price: {
        price_id: '52ac4746-5814-4ab2-b654-a92f2a880a30',
        value: {
          cent_amount: 5400,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/0378/2560/9865/products/WHOOP-Shopify-BANDs-800x800-SuperKnit-Bicep-Onyx.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/0378/2560/9865/products/GEN-4-Shopify-BANDS-View2_600x400-SuperKnit-Bicep-Onyx_fe5c3910-c91a-471c-a778-8ac703733005.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/0378/2560/9865/products/WHOOP-Shopify-BANDS-View3_600x400-SuperKnit-Bicep-Onyx_0c33914b-ec85-4ca8-97bf-8c8399d6b72b.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/onyx_64x64.png?v=1698244400.429',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Onyx',
        },
        {
          name: 'size',
          value: {
            key: 'small',
            label: 'S/M',
          },
        },
        {
          name: 'active',
          value: true,
        },
        {
          name: 'tax_code',
          value: 'PC040200',
        },
        {
          name: 'size-guide-key',
          value: 'BICEP',
        },
        {
          name: 'gender',
          value: 'unisex',
        },
      ],
    },
    {
      variant_id: 2,
      sku: '957-01-000-000002',
      price: {
        price_id: '03c3e757-ea57-4f2b-a327-35f8c9210cc5',
        value: {
          cent_amount: 5500,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/0378/2560/9865/products/WHOOP-Shopify-BANDs-800x800-SuperKnit-Bicep-Onyx.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/0378/2560/9865/products/GEN-4-Shopify-BANDS-View2_600x400-SuperKnit-Bicep-Onyx_fe5c3910-c91a-471c-a778-8ac703733005.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/0378/2560/9865/products/WHOOP-Shopify-BANDS-View3_600x400-SuperKnit-Bicep-Onyx_0c33914b-ec85-4ca8-97bf-8c8399d6b72b.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/onyx_64x64.png?v=1698244400.429',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Onyx',
        },
        {
          name: 'size',
          value: {
            key: 'large',
            label: 'L/XL',
          },
        },
        {
          name: 'tax_code',
          value: 'PC040200',
        },
        {
          name: 'active',
          value: false,
        },
        {
          name: 'size-guide-key',
          value: 'BICEP',
        },
        {
          name: 'gender',
          value: 'unisex',
        },
      ],
    },
  ],
};

export const TEST_BAND_WITH_OUT_OF_STOCK_VARIANT: Product = {
  product_id: 'test-id',
  name: 'Test Band',
  description: 'Test band',
  product_type: 'wrist-band',
  key: 'test-band',
  variants: [
    {
      variant_id: 1,
      sku: '955-01-000-000009',
      price: {
        price_id: '9d517a9d-7687-409b-b882-1e50626b641a',
        value: {
          cent_amount: 4900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [],
      in_stock: false,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Heather',
        },
        {
          name: 'active',
          value: true,
        },
      ],
    },
    {
      variant_id: 2,
      sku: '955-01-000-000010',
      price: {
        price_id: '9d517a9d-7687-409b-b882-1e50626b641a',
        value: {
          cent_amount: 4900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [],
      in_stock: true,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Heather',
        },
        {
          name: 'active',
          value: true,
        },
      ],
    },
  ],
};

export const OUT_OF_STOCK_TEST_BAND: Product = {
  product_id: 'test-id',
  name: 'Test Band',
  description: 'Test band',
  product_type: 'wrist-band',
  key: 'test-band',
  variants: [
    {
      variant_id: 1,
      sku: '955-01-000-000009',
      price: {
        price_id: '9d517a9d-7687-409b-b882-1e50626b641a',
        value: {
          cent_amount: 4900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [],
      in_stock: false,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Heather',
        },
        {
          name: 'active',
          value: true,
        },
      ],
    },
    {
      variant_id: 2,
      sku: '955-01-000-000010',
      price: {
        price_id: '9d517a9d-7687-409b-b882-1e50626b641a',
        value: {
          cent_amount: 4900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [],
      in_stock: false,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Heather',
        },
        {
          name: 'active',
          value: true,
        },
      ],
    },
  ],
};

export const JUNGLE_BICEP_BAND: Product = {
  product_id: '3d00bb7e-9fc0-462d-a6a7-79dc6b5922d1',
  name: 'Jungle Superknit Bicep Band',
  description:
    'When it comes to any performance or profession, nothing should stand in the way of unlocking your potential. It’s why we designed our premium SuperKnit Bicep Band that frees your wrist and allows you to capture data on your upper arm. Engineered with an ultra-soft backing and microfilament yarn for enhanced comfort and durability—so you can stay in the zone with zero distractions. Includes Band, Hook, Clasp, and Fast Link™ slider that makes swapping bands easier than ever. Only compatible with WHOOP® 4.0.',
  product_type: 'bicep-band',
  key: 'jungle-superknit-bicep-band',
  variants: [
    {
      variant_id: 1,
      sku: '956-02-000-000002',
      price: {
        price_id: '074c1362-38cb-49fd-baf3-ebfaa869770a',
        value: {
          cent_amount: 6400,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/junglebicepv2.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/Bicep_Camo_PDP_800x800_FlatRender_Jungle_2.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/jungle-camo_64x64.png',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Jungle',
        },
        {
          name: 'size',
          value: {
            key: 'small',
            label: 'S/M',
          },
        },
        {
          name: 'tax_code',
          value: 'PC040200',
        },
        {
          name: 'active',
          value: true,
        },
        {
          name: 'size-guide-key',
          value: 'BICEP',
        },
        {
          name: 'gender',
          value: 'unisex',
        },
      ],
    },
    {
      variant_id: 2,
      sku: '956-01-003-000002',
      price: {
        price_id: 'aaaaff9f-71fc-4159-9430-bb87c801d256',
        value: {
          cent_amount: 6500,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/junglebicepv2.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/Bicep_Camo_PDP_800x800_FlatRender_Jungle_2.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/jungle-camo_64x64.png',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'material',
          value: {
            key: 'superknit',
            label: 'SuperKnit',
          },
        },
        {
          name: 'color',
          value: 'Jungle',
        },
        {
          name: 'size',
          value: {
            key: 'large',
            label: 'L/XL',
          },
        },
        {
          name: 'tax_code',
          value: 'PC040200',
        },
        {
          name: 'active',
          value: false,
        },
        {
          name: 'size-guide-key',
          value: 'BICEP',
        },
        {
          name: 'gender',
          value: 'unisex',
        },
      ],
    },
  ],
};

export const BLACK_BOXER: Product = {
  product_id: 'ed38c7e4-75dd-4db6-989a-628e590872ff',
  name: 'Black Any-wear Boxer Smart Apparel',
  description:
    'Monitor your metrics without sacrificing style or comfort with our Any-Wear Boxer designed with soft cotton-blend fabric, a flexible elastic waistband, and a 3” inseam. Made to be worn for the hours of the day you aren’t breaking a sweat. Designed with an internal WHOOP® pod to house your WHOOP 4.0 and keep it in place wherever your day takes you.',
  product_type: 'anywhere-boxer',
  key: null,
  variants: [
    {
      variant_id: 1,
      sku: '456-02-001-000002',
      price: {
        price_id: 'e3015c7e-1bf0-4e70-8b92-0f3c6466f5d5',
        value: {
          cent_amount: 3000,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday-Boxers-Band-RT-5in-Black.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_EverydayBoxers-Lifestyle-5in.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday_Boxers_Black.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/black_64x64.png',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'color',
          value: 'Black',
        },
        {
          name: 'size',
          value: {
            key: 'small',
            label: 'S',
          },
        },
        {
          name: 'inseam',
          value: {
            key: 'five',
            label: '5"',
          },
        },
        {
          name: 'size-guide-key',
          value: 'MENS_BOXERS',
        },
        {
          name: 'gender',
          value: 'mens',
        },
      ],
    },
    {
      variant_id: 3,
      sku: '456-01-002-000002',
      price: {
        price_id: '19a88fcc-ff80-4aaf-8dbd-2a140dde1a9e',
        value: {
          cent_amount: 2900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/black_64x64.png',
          label: 'swatch',
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday-Boxers-Band-RT-3in-Black.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_EverydayBoxers-Lifestyle-3in.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_EverydayBoxers-Closeup-3in.png',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'color',
          value: 'Black',
        },
        {
          name: 'size',
          value: {
            key: 'medium',
            label: 'M',
          },
        },
        {
          name: 'inseam',
          value: {
            key: 'three',
            label: '3"',
          },
        },
        {
          name: 'size-guide-key',
          value: 'MENS_BOXERS',
        },
        {
          name: 'gender',
          value: 'mens',
        },
      ],
    },
    {
      variant_id: 4,
      sku: '456-02-002-000002',
      price: {
        price_id: '871a4fb0-c2bb-4e9e-9fcd-a457a24c00f9',
        value: {
          cent_amount: 3000,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/black_64x64.png',
          label: 'swatch',
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday-Boxers-Band-RT-5in-Black.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_EverydayBoxers-Lifestyle-5in.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday_Boxers_Black.png',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'color',
          value: 'Black',
        },
        {
          name: 'size',
          value: {
            key: 'medium',
            label: 'M',
          },
        },
        {
          name: 'inseam',
          value: {
            key: 'five',
            label: '5"',
          },
        },
        {
          name: 'size-guide-key',
          value: 'MENS_BOXERS',
        },
        {
          name: 'gender',
          value: 'mens',
        },
      ],
    },
    {
      variant_id: 5,
      sku: '456-01-001-000002',
      price: {
        price_id: 'ef769682-52b4-48e3-b018-ecc6b9caa224',
        value: {
          cent_amount: 2900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday-Boxers-Band-RT-3in-Black.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_EverydayBoxers-Lifestyle-3in.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_EverydayBoxers-Closeup-3in.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/black_64x64.png',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'color',
          value: 'Black',
        },
        {
          name: 'size',
          value: {
            key: 'small',
            label: 'S',
          },
        },
        {
          name: 'inseam',
          value: {
            key: 'three',
            label: '3"',
          },
        },
        {
          name: 'size-guide-key',
          value: 'MENS_BOXERS',
        },
        {
          name: 'gender',
          value: 'mens',
        },
      ],
    },
  ],
};

export const CHARCOAL_BOXER = {
  product_id: 'ae56a979-c490-4286-bc98-2eae5974550b',
  name: 'Charcoal ANY-WEAR™ Boxer | Smart Apparel',
  description:
    'Monitor your metrics without sacrificing style or comfort with our Any-Wear Boxer designed with soft cotton-blend fabric, a flexible elastic waistband, and a 3” inseam. Made to be worn for the hours of the day you aren’t breaking a sweat. Designed with an internal WHOOP® pod to house your WHOOP 4.0 and keep it in place wherever your day takes you.',
  product_type: 'anywhere-boxer',
  key: null,
  variants: [
    {
      variant_id: 2,
      sku: '457-01-001-000002',
      price: {
        price_id: '67d41c17-dd9d-42d9-b085-3134aec5fdaa',
        value: {
          cent_amount: 2900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/charcoal_64x64.png',
          label: 'swatch',
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday_Boxers_Charcoal.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800-Everyday_Boxer_3_-Charcoal-1pk_3726121d-1e87-47f2-829a-79463d132625.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_3_-Charcoal-6_85c60b2f-f9a8-4d54-a7ee-f706c9fcf788.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800-Everyday_Boxer_3_-Charcoal_d92b787b-ac9d-4084-ab77-c6db2cc95d81.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_3_-Charcoal-1_6c666ed1-b470-40c3-b831-12388dab4a71.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_3_-Charcoal-2_9a8ff729-ce91-4b96-8443-41c24109fd34.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_3_-Charcoal-4_805fbdc2-ae3e-476a-acf4-fddb29d1816a.png',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'color',
          value: 'Charcoal',
        },
        {
          name: 'size',
          value: {
            key: 'small',
            label: 'S',
          },
        },
        {
          name: 'inseam',
          value: {
            key: 'three',
            label: '3"',
          },
        },
        {
          name: 'size-guide-key',
          value: 'MENS_BOXERS',
        },
        {
          name: 'gender',
          value: 'mens',
        },
      ],
    },
    {
      variant_id: 3,
      sku: '457-01-002-000002',
      price: {
        price_id: 'db8594b6-6ec2-42a6-9297-595076f68275',
        value: {
          cent_amount: 2900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/charcoal_64x64.png',
          label: 'swatch',
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday_Boxers_Charcoal.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800-Everyday_Boxer_3_-Charcoal-1pk_3726121d-1e87-47f2-829a-79463d132625.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_3_-Charcoal-6_85c60b2f-f9a8-4d54-a7ee-f706c9fcf788.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800-Everyday_Boxer_3_-Charcoal_d92b787b-ac9d-4084-ab77-c6db2cc95d81.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_3_-Charcoal-1_6c666ed1-b470-40c3-b831-12388dab4a71.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_3_-Charcoal-2_9a8ff729-ce91-4b96-8443-41c24109fd34.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_3_-Charcoal-4_805fbdc2-ae3e-476a-acf4-fddb29d1816a.png',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'color',
          value: 'Charcoal',
        },
        {
          name: 'size',
          value: {
            key: 'medium',
            label: 'M',
          },
        },
        {
          name: 'inseam',
          value: {
            key: 'three',
            label: '3"',
          },
        },
        {
          name: 'size-guide-key',
          value: 'MENS_BOXERS',
        },
        {
          name: 'gender',
          value: 'mens',
        },
      ],
    },
    {
      variant_id: 4,
      sku: '457-02-002-000002',
      price: {
        price_id: 'dc2154da-8080-4fe0-b8f4-14bb12a016dc',
        value: {
          cent_amount: 3000,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/charcoal_64x64.png',
          label: 'swatch',
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday_Boxers_Charcoal.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800-Everyday_Boxer_5_-Charcoal-1pk_b809dc48-9c6e-402a-b441-da6bd991f2a0.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-1_e5530787-2e49-4f1d-8572-e4a63f5a9f9e.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-2_95ca7208-fda8-4c2e-80a4-9556126bffbe.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-4_f4d70cf2-1feb-4ce1-a8b1-946e9bdf8edd.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-5_1e789428-a8bd-43cb-9677-a244350df9ac.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-8_027298a2-99a0-40ab-86fd-52389600d30b.png',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'color',
          value: 'Charcoal',
        },
        {
          name: 'size',
          value: {
            key: 'medium',
            label: 'M',
          },
        },
        {
          name: 'inseam',
          value: {
            key: 'five',
            label: '5"',
          },
        },
        {
          name: 'size-guide-key',
          value: 'MENS_BOXERS',
        },
        {
          name: 'gender',
          value: 'mens',
        },
      ],
    },
    {
      variant_id: 1,
      sku: '457-02-001-000002',
      price: {
        price_id: '0588f76c-9da9-4581-ba25-a29bd31c12dc',
        value: {
          cent_amount: 3000,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800_Pod-Circle_Everyday_Boxers_Charcoal.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x800-Everyday_Boxer_5_-Charcoal-1pk_b809dc48-9c6e-402a-b441-da6bd991f2a0.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-1_e5530787-2e49-4f1d-8572-e4a63f5a9f9e.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-2_95ca7208-fda8-4c2e-80a4-9556126bffbe.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-4_f4d70cf2-1feb-4ce1-a8b1-946e9bdf8edd.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-5_1e789428-a8bd-43cb-9677-a244350df9ac.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/WHOOP-Shopify-APPAREL-800x1000-Everyday_Boxer_5_-Charcoal-8_027298a2-99a0-40ab-86fd-52389600d30b.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/files/charcoal_64x64.png',
          label: 'swatch',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'color',
          value: 'Charcoal',
        },
        {
          name: 'size',
          value: {
            key: 'small',
            label: 'S',
          },
        },
        {
          name: 'inseam',
          value: {
            key: 'five',
            label: '5"',
          },
        },
        {
          name: 'size-guide-key',
          value: 'MENS_BOXERS',
        },
        {
          name: 'gender',
          value: 'mens',
        },
      ],
    },
  ],
};

export const STARTER_PACK: Product = {
  product_id: '1f53f116-b3b5-47ca-95e0-6e7c677313cf',
  name: 'Starter Pack',
  description:
    '<p>Choose two colors to round out your starter band collection, and an additional battery so you always have a backup. </p><p>Includes Band, Hook, Clasp, and Fast Link™ slider that makes swapping bands easier than ever. Only compatible with WHOOP® 4.0.</p><p>Includes 2 SuperKnit Bands and 1 Battery.</p>',
  product_type: 'pack',
  key: 'starter-pack-v2',
  variants: [
    {
      variant_id: 1,
      sku: 'PCK-0020-V2',
      price: {
        price_id: '45e58ffe-fc30-4f4e-9d04-342d82e6a1ca',
        value: {
          cent_amount: 14700,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: {
          value: {
            cent_amount: 12495,
            currency_code: 'USD',
            fraction_digits: 2,
          },
          discount: {
            discount_id: '9df35cd8-fb0a-4d6d-88c0-728ad3bda359',
            key: 'discount-pack-fifteen-percent',
            permyriad: 1500,
          },
        },
      },
      images: [
        {
          url: 'https://starterpackimage.png',
          label: 'cart-item-display',
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'category_1',
          value: {
            id: '3ca6dbbb-1d0e-455e-90e4-080d79a68dbc',
            obj: null,
            typeId: 'category',
          },
        },
        {
          name: 'category_2',
          value: {
            id: 'e128136b-c9eb-4224-9260-84e68e3441d6',
            obj: null,
            typeId: 'category',
          },
        },
        {
          name: 'category_3',
          value: {
            id: 'e128136b-c9eb-4224-9260-84e68e3441d6',
            obj: null,
            typeId: 'category',
          },
        },
      ],
    },
  ],
};

export const WYW_PRODUCT: Product = {
  product_id: '76d5393d-e3de-477c-9f4e-29b0d86627a3',
  name: 'WHOOP Your Way',
  description: '',
  product_type: 'whoop-your-way',
  key: 'whoop-your-way',
  variants: [
    {
      variant_id: 1,
      sku: 'wyw-sku',
      price: {
        price_id: 'bc1986ae-b1a9-4104-8dbb-4b7b66c84323',
        value: { cent_amount: 1000, currency_code: 'USD', fraction_digits: 2 },
        discounted: null,
      },
      images: [
        {
          url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/wyw_banner-qKZmGaj1.png',
          label: 'product-card-image',
        },
        {
          url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/wyw_pencil-GMwqfD3e.png',
          label: 'swatch',
        },
        {
          url: 'https://cdn.whoop.com/v0/WYW_Component_Front__Base.png',
          label: 'cart-item-display',
        },
        {
          url: 'https://cdn.whoop.com/v0/WYW_Component_Back__Base.png',
          label: 'back',
        },
      ],
      in_stock: false,
      attributes: [
        { name: 'display-price', value: { en: '$54+' } },
        {
          name: 'material',
          value: { key: 'whoop-your-way', label: 'WHOOP Your Way' },
        },
        { name: 'color', value: 'WHOOP Your Way' },
      ],
    },
  ],
};

export const mockBoxerProducts: Product[] = [BLACK_BOXER, CHARCOAL_BOXER];

export const mockBicepBandProducts: Product[] = [
  ONYX_BICEP_BAND,
  JUNGLE_BICEP_BAND,
];

export const mockBicepBandAccessoryCategory: AccessoryCategory = {
  category_id: 'superknit-bands',
  name: 'Superknit Band',
  key: 'superknit-band-parent',
  products: mockBicepBandProducts,
  categories: [],
  description: null,
};

export const mockBoxerAccessoryCategory: AccessoryCategory = {
  category_id: 'anywhere-boxer',
  name: 'Anywhere Boxer',
  key: 'anywhere-boxer',
  products: mockBoxerProducts,
  categories: [],
  description: null,
};

export const mockBatteryAccessoryCategory: AccessoryCategory = {
  category_id: 'battery-pack',
  name: 'Extra Battery',
  key: 'battery-pack',
  products: [
    {
      product_id: '6411600f-4bec-4636-9f69-545cd6e85182',
      name: 'Battery Pack 4.0',
      description:
        '<p data-mce-fragment="1"><span data-mce-fragment="1" style="font-weight: 400;" data-mce-style="font-weight: 400;">You’ve got goals and so do we—like keeping you charged wherever your day takes you. Get continuous data for days on a single charge with our </span><span data-mce-fragment="1" style="font-weight: 400;" data-mce-style="font-weight: 400;">waterproof*</span><span data-mce-fragment="1" style="font-weight: 400;" data-mce-style="font-weight: 400;"> battery pack that enables you to charge on the go while you wear it. </span></p>\n<p data-mce-fragment="1"><span data-mce-fragment="1" style="font-weight: 400;" data-mce-style="font-weight: 400;">To charge your WHOOP® 4.0, simply slide the battery pack on your device to charge on the go. Use the included USB cable to recharge your battery pack from a laptop. </span></p>\n<p><span data-mce-fragment="1" style="font-weight: 400;" data-mce-style="font-weight: 400;">*</span><span data-mce-fragment="1" style="font-weight: 400;" data-mce-style="font-weight: 400;">IP68 dustproof and water-resistant up to 1 meter for 2 hours.</span></p>\n<p><span data-mce-fragment="1" style="font-weight: 400;" data-mce-style="font-weight: 400;">Due to high demand, battery orders are limited to 2 per order. New stock arriving weekly.</span></p>\n<p> </p>',
      product_type: 'battery',
      key: 'battery-pack',
      variants: [
        {
          variant_id: 1,
          sku: 'battery-4-sku',
          price: {
            price_id: '7b04de13-1b1a-42c1-ac25-152590ab8e10',
            value: {
              cent_amount: 4999,
              currency_code: 'USD',
              fraction_digits: 2,
            },
            discounted: null,
          },
          images: [
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/GEN-4-Shopify-Batter-6zO8Lu9r.png',
              label: null,
            },
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/GEN-4-Shopify-Batter-NgoncHgh.png',
              label: null,
            },
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/WHOOP-Shopify-Batter-UBDSqs6P.png',
              label: null,
            },
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/GEN-4-CartImages-Bat-mLvaMGv3.png',
              label: null,
            },
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/GEN-4-Shopify-Batter-W2IJWGV8.png',
              label: 'cart-item-display',
            },
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/GEN-4-Shopify-Batter-aubJ43KK.png',
              label: null,
            },
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/WHOOP-Shopify-Batter-A-2kIdxD.png',
              label: null,
            },
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/GEN-4-CartImages-Bat-OBB7R2UX.png',
              label: null,
            },
          ],
          in_stock: true,
          attributes: [
            {
              name: 'color',
              value: 'Black',
            },
            {
              name: 'active',
              value: false,
            },
            {
              name: 'tax_code',
              value: 'PC080400',
            },
          ],
        },
      ],
    },
  ],
  categories: [],
  description: null,
};

export const mockStarterPackAccessoryCategory: AccessoryCategory = {
  category_id: '563b2699-18bb-41c2-b6cd-5361503729b1',
  name: 'Starter Pack',
  key: null,
  products: [
    {
      product_id: '1f53f116-b3b5-47ca-95e0-6e7c677313cf',
      name: 'Starter Pack',
      description:
        '<p>Choose two colors to round out your starter band collection, and an additional battery so you always have a backup. </p><p>Includes Band, Hook, Clasp, and Fast Link™ slider that makes swapping bands easier than ever. Only compatible with WHOOP® 4.0.</p><p>Includes 2 SuperKnit Bands and 1 Battery.</p>',
      product_type: 'pack',
      key: 'starter-pack-v2',
      variants: [
        {
          variant_id: 1,
          sku: 'PCK-0020-V2',
          price: {
            price_id: '45e58ffe-fc30-4f4e-9d04-342d82e6a1ca',
            value: {
              cent_amount: 14700,
              currency_code: 'USD',
              fraction_digits: 2,
            },
            discounted: {
              value: {
                cent_amount: 12495,
                currency_code: 'USD',
                fraction_digits: 2,
              },
              discount: {
                discount_id: '9df35cd8-fb0a-4d6d-88c0-728ad3bda359',
                key: 'discount-pack-fifteen-percent',
                permyriad: 1500,
              },
            },
          },
          images: [
            {
              url: 'https://images.us-east-2.aws.commercetools.com/24a53863-b390-4e36-9ae5-99e972a4b95b/starterpackimageupda-flk6n-KR.png',
              label: 'cart-item-display',
            },
          ],
          in_stock: true,
          attributes: [
            {
              name: 'category_1',
              value: {
                id: 'battery-pack',
                obj: null,
                typeId: 'category',
              },
            },
            {
              name: 'category_2',
              value: {
                id: 'superknit-bands',
                obj: null,
                typeId: 'category',
              },
            },
            {
              name: 'category_3',
              value: {
                id: 'superknit-bands',
                obj: null,
                typeId: 'category',
              },
            },
          ],
        },
      ],
    },
  ],
  categories: [],
  description: null,
};

export const mockSuperknitProducts: Product[] = [
  HEATHER_SUPERKNIT_PRODUCT,
  CITRA_SUPERKNIT_PRODUCT,
];

export const mockSuperknitAccessoryCategory: AccessoryCategory = {
  category_id: 'superknit-bands',
  name: 'Superknit Band',
  key: 'superknit-band-parent',
  products: [],
  categories: [
    {
      category_id: 'superknit-solid',
      name: 'Solid',
      key: null,
      products: [HEATHER_SUPERKNIT_PRODUCT],
      categories: [],
      description: null,
    },
    {
      category_id: 'superknit-metal',
      name: 'Metal Details',
      key: null,
      products: [CITRA_SUPERKNIT_PRODUCT],
      categories: [],
      description: null,
    },
  ],
  description: null,
};

export const mockSuperknitAccessoryCategoryOOS: AccessoryCategory = {
  ...mockSuperknitAccessoryCategory,
  categories: [
    {
      category_id: 'superknit-solid',
      name: 'Solid',
      key: null,
      products: [OOS_HEATHER_SUPERKNIT_PRODUCT],
      categories: [],
      description: null,
    },
  ],
};

export const mockWywCategory: AccessoryCategory = {
  category_id: '12c03ce8-d72d-4a7b-856a-374b8ba79364',
  name: 'WHOOP Your Way',
  key: 'whoop-your-way-kit',
  products: [WYW_PRODUCT],
  categories: [],
  description:
    'Customize the material, band, and hardware pieces of your WHOOP.',
};

export const mockAccessoryGroup: AccessoryCategory[] = [
  {
    category_id: 'superknit-bands',
    name: 'Superknit Band',
    key: 'superknit-band-parent',
    products: [],
    categories: [mockSuperknitAccessoryCategory],
    description: null,
  },
];

const familyImage = [
  {
    url: 'https://join.whoop.com/assets/whoop-circle-logo@2x.png?fm=webp&q=75&w=32',
    label: 'cart-item-display',
  },
];

const defaultFamilyAttributes = [
  {
    name: 'line-item-description',
    value: {
      en: 'Family Membership',
    },
  },
  {
    name: 'line-item-title',
    value: {
      en: 'WHOOP Family Membership',
    },
  },
  {
    name: 'subscriptionType',
    value: {
      key: 'annual',
      label: '12 month, 24 month',
    },
  },
  {
    name: 'trialDays',
    value: 365,
  },
  {
    name: 'commitmentDays',
    value: 0,
  },
  {
    name: 'active',
    value: true,
  },
];
export const FAMILY_VARIANT_SIZE_2 = {
  variant_id: 1,
  sku: '2-Person-Family-Subscription',
  price: {
    price_id: '78fec7b8-7151-4e3b-8325-4a457ad2d330',
    value: {
      cent_amount: 43000,
      currency_code: 'USD',
      fraction_digits: 2,
    },
    discounted: null,
  },
  images: familyImage,
  in_stock: false,
  attributes: [
    ...defaultFamilyAttributes,
    {
      name: 'family-size',
      value: 2,
    },
    {
      name: 'family-line-item-description',
      value: {
        en: '2 Members - Annual Membership',
      },
    },
  ],
};

export const FAMILY_VARIANT_SIZE_3 = {
  variant_id: 2,
  sku: '3-Person-Family-Subscription',
  price: {
    price_id: 'a315a894-8079-4bc1-aaab-2acb2bfb7643',
    value: {
      cent_amount: 62700,
      currency_code: 'USD',
      fraction_digits: 2,
    },
    discounted: null,
  },
  images: familyImage,
  in_stock: false,
  attributes: [
    ...defaultFamilyAttributes,
    {
      name: 'family-size',
      value: 3,
    },
    {
      name: 'family-line-item-description',
      value: {
        en: '3 Members - Annual Membership',
      },
    },
  ],
};

export const FAMILY_VARIANT_SIZE_4 = {
  variant_id: 3,
  sku: '4-Person-Family-Subscription',
  price: {
    price_id: '3c3a49e1-c6d3-45e6-9df3-d1b1a1c0aa70',
    value: {
      cent_amount: 79600,
      currency_code: 'USD',
      fraction_digits: 2,
    },
    discounted: null,
  },
  images: familyImage,
  in_stock: false,
  attributes: [
    ...defaultFamilyAttributes,
    {
      name: 'family-size',
      value: 4,
    },
    {
      name: 'family-line-item-description',
      value: {
        en: '4 Members - Annual Membership',
      },
    },
  ],
};

export const FAMILY_VARIANT_SIZE_5 = {
  variant_id: 4,
  sku: '5-Person-Family-Subscription',
  price: {
    price_id: 'e376c089-bb18-4871-8fe5-031484e7bd02',
    value: {
      cent_amount: 99500,
      currency_code: 'USD',
      fraction_digits: 2,
    },
    discounted: null,
  },
  images: familyImage,
  in_stock: false,
  attributes: [
    ...defaultFamilyAttributes,
    {
      name: 'family-size',
      value: 5,
    },
    {
      name: 'family-line-item-description',
      value: {
        en: '5 Members - Annual Membership',
      },
    },
  ],
};

export const FAMILY_VARIANT_SIZE_6 = {
  variant_id: 5,
  sku: '6-Person-Family-Subscription',
  price: {
    price_id: 'fd74c2bb-52a6-4e2f-a914-58ec30162f93',
    value: {
      cent_amount: 119400,
      currency_code: 'USD',
      fraction_digits: 2,
    },
    discounted: null,
  },
  images: familyImage,
  in_stock: false,
  attributes: [
    ...defaultFamilyAttributes,
    {
      name: 'family-size',
      value: 6,
    },
    {
      name: 'family-line-item-description',
      value: {
        en: '6 Members - Annual Membership',
      },
    },
  ],
};

export const FAMILY_MEMBERSHIP: Product = {
  product_id: '7eeaacba-660e-45cc-a3e1-ee56a2c5e946',
  name: 'Family Membership',
  description: '<p>Family Membership</p>',
  product_type: 'membership',
  key: 'family-membership',
  variants: [
    FAMILY_VARIANT_SIZE_2,
    FAMILY_VARIANT_SIZE_3,
    FAMILY_VARIANT_SIZE_4,
    FAMILY_VARIANT_SIZE_5,
    FAMILY_VARIANT_SIZE_6,
  ],
};

export const ANNUAL_MEMBERSHIP: Product = {
  product_id: '7dd7f52d-83b1-4fbe-8f4d-d1d5d010332c',
  name: 'WHOOP Membership 12-Month',
  description: '12 Month Membership',
  product_type: 'membership',
  key: 'whoop-membership-12m',
  variants: [
    {
      variant_id: 1,
      sku: '940-000003-312',
      price: {
        price_id: 'd380f421-ca8d-42b6-a632-5ed8cce3db05',
        value: {
          cent_amount: 23900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [],
      in_stock: true,
      attributes: [
        {
          name: 'trialDays',
          value: 365,
        },
        {
          name: 'commitmentDays',
          value: 0,
        },
        {
          name: 'active',
          value: true,
        },
        {
          name: 'line-item-description',
          value: {
            'en-US': 'Annual Membership',
          },
        },
      ],
    },
  ],
};

export const TWO_YEAR_MEMBERSHIP: Product = {
  product_id: '7708ce3e-0a12-419a-b6a9-87bd8bf97e9b',
  name: '24 Month Membership',
  description: '24 Month Membership',
  product_type: 'membership',
  key: 'whoop-membership-24m',
  variants: [
    {
      variant_id: 1,
      sku: '940-000003-124',
      price: {
        price_id: '24140557-49f4-4196-a43d-c6cff5fbe08f',
        value: {
          cent_amount: 39900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://join.whoop.com/assets/whoop-circle-logo@2x.png?fm=webp&q=75&w=32',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'trialDays',
          value: 730,
        },
        {
          name: 'commitmentDays',
          value: 0,
        },
        {
          name: 'active',
          value: true,
        },
        {
          name: 'line-item-title',
          value: {
            'en-US': 'WHOOP Membership',
          },
        },
        {
          name: 'line-item-description',
          value: {
            'en-US': '24 Month Membership',
          },
        },
      ],
    },
  ],
};

export const MONTHLY_MEMBERSHIP: Product = {
  product_id: '9104b248-0c67-4723-b41d-69abce7428f9',
  name: 'WHOOP Monthly Membership',
  description: 'Whoop pay-per-month membership',
  product_type: 'membership',
  key: 'whoop-membership-mtm',
  variants: [
    {
      variant_id: 1,
      sku: '940-000003-121',
      price: {
        price_id: 'a6d0380c-50d2-4ba4-b3ea-d52c0eb231af',
        value: {
          cent_amount: 3000,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [],
      in_stock: true,
      attributes: [
        {
          name: 'active',
          value: true,
        },
        {
          name: 'tax_code',
          value: 'TEST',
        },
        {
          name: 'line-item-description',
          value: {
            'en-US': 'Monthly Membership',
          },
        },
      ],
    },
  ],
};

export const TRIAL_MEMBERSHIP: Product = {
  product_id: '9104b248-0c67-4723-b41d-69abce7428f9',
  name: 'WHOOP Membership 1-Month Trial',
  description: '1 Month Free Trial',
  product_type: 'membership',
  key: 'whoop-membership-1m-trial',
  variants: [
    {
      variant_id: 1,
      sku: '940-000003-120',
      price: {
        price_id: 'a6d0380c-50d2-4ba4-b3ea-d52c0eb231af',
        value: {
          cent_amount: 0,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [],
      in_stock: true,
      attributes: [
        {
          name: 'trialDays',
          value: 30,
        },
        {
          name: 'commitmentDays',
          value: 365,
        },
        {
          name: 'active',
          value: true,
        },
        {
          name: 'tax_code',
          value: 'TEST',
        },
        {
          name: 'line-item-description',
          value: {
            'en-US': 'Trial',
          },
        },
      ],
    },
  ],
};

export const DISCOUNTED_ANNUAL_MEMBERSHIP: Product = {
  product_id: '7dd7f52d-83b1-4fbe-8f4d-d1d5d010332c',
  name: 'WHOOP Membership 12-Month',
  description: '12 Month Membership',
  product_type: 'membership',
  key: 'whoop-membership-12m',
  variants: [
    {
      variant_id: 1,
      sku: '940-000003-312',
      price: {
        price_id: 'd380f421-ca8d-42b6-a632-5ed8cce3db05',
        value: {
          cent_amount: 23900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: {
          value: {
            cent_amount: 21510,
            currency_code: 'USD',
            fraction_digits: 2,
          },
          discount: {
            discount_id: 'ee691e85-b358-42bf-86d1-701563af4d2b',
            key: 'cyber-test',
            permyriad: 1000,
          },
        },
      },
      images: [],
      in_stock: true,
      attributes: [
        {
          name: 'trialDays',
          value: 365,
        },
        {
          name: 'commitmentDays',
          value: 0,
        },
        {
          name: 'active',
          value: true,
        },
      ],
    },
  ],
};

export const DISCOUNTED_TWO_YEAR_MEMBERSHIP: Product = {
  product_id: '7708ce3e-0a12-419a-b6a9-87bd8bf97e9b',
  name: '24 Month Membership',
  description: '24 Month Membership',
  product_type: 'membership',
  key: 'whoop-membership-24m',
  variants: [
    {
      variant_id: 1,
      sku: '940-000003-124',
      price: {
        price_id: '24140557-49f4-4196-a43d-c6cff5fbe08f',
        value: {
          cent_amount: 39900,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: {
          value: {
            cent_amount: 35910,
            currency_code: 'USD',
            fraction_digits: 2,
          },
          discount: {
            discount_id: 'ee691e85-b358-42bf-86d1-701563af4d2b',
            key: 'cyber-test',
            permyriad: 1000,
          },
        },
      },
      images: [
        {
          url: 'https://join.whoop.com/assets/whoop-circle-logo@2x.png?fm=webp&q=75&w=32',
          label: null,
        },
      ],
      in_stock: true,
      attributes: [
        {
          name: 'trialDays',
          value: 730,
        },
        {
          name: 'commitmentDays',
          value: 0,
        },
        {
          name: 'active',
          value: true,
        },
        {
          name: 'line-item-title',
          value: {
            'en-US': 'WHOOP Membership',
          },
        },
        {
          name: 'line-item-description',
          value: {
            'en-US': '24 Month Membership',
          },
        },
      ],
    },
  ],
};

export const ENGRAVING_PRODUCT: Product = {
  product_id: '967aa04d-c872-46af-a625-bd8ad46e9e61',
  name: 'Engraving',
  description: 'WHOOP device with an engraving',
  product_type: 'engraving',
  key: 'engraving',
  variants: [
    {
      variant_id: 1,
      sku: '970-002-000',
      price: {
        price_id: 'f7ec9399-7eb3-4547-8efc-d0fa1a827245',
        value: {
          cent_amount: 2500,
          currency_code: 'USD',
          fraction_digits: 2,
        },
        discounted: null,
      },
      images: [
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/engraving-text-gen4_strap_text.png',
          label: null,
        },
        {
          url: 'https://cdn.shopify.com/s/files/1/1040/0152/products/engraving_preview_0.52_-0.12_0.19_0.52_0.02_0.29_strap.png',
          label: 'preview',
        },
      ],
      in_stock: true,
      attributes: [],
    },
  ],
};

export const mockWywCatalog: AccessoryCategory[] = [
  {
    category_id: '66704167-3b26-445d-a20e-37061552792b',
    name: 'Band',
    key: 'wyw-band',
    products: [],
    categories: [
      {
        category_id: '0f477d0d-b012-4087-aee2-7a335fd0306d',
        name: 'SuperKnit',
        key: 'wyw-band-superknit',
        products: [CITRA_SUPERKNIT_PRODUCT, HEATHER_SUPERKNIT_PRODUCT],
        categories: [],
        description:
          'Designed for 24/7 wear\nPremium construction\nMade with softer micro-filament yarn for enhanced comfort',
      },
      {
        category_id: '715fcbcb-0a00-45d9-91b9-3eb393957c3e',
        name: 'ProKnit',
        key: 'wyw-band-proknit',
        products: [HEATHER_SUPERKNIT_PRODUCT, CITRA_SUPERKNIT_PRODUCT],
        categories: [],
        description:
          'Designed for performance\nLightweight construction\nMade with high-tenacity yarn for maximum durability & stability',
      },
    ],
    description: '',
  },
  {
    category_id: '3e2e86a2-6381-4265-a299-1ffedb6d4b4d',
    name: 'Clasp',
    key: 'wyw-clasp',
    products: [HEATHER_SUPERKNIT_PRODUCT, CITRA_SUPERKNIT_PRODUCT],
    categories: [],
    description: '',
  },
  {
    category_id: 'de72d3df-21b9-4c1c-9ac2-44b3180e6067',
    name: 'Hook',
    key: 'wyw-hook',
    products: [HEATHER_SUPERKNIT_PRODUCT, CITRA_SUPERKNIT_PRODUCT],
    categories: [],
    description: '',
  },
  {
    category_id: 'ab930456-72fc-4b6c-8480-fee605768b1a',
    name: 'Fast Link™',
    key: 'wyw-fast-link',
    products: [HEATHER_SUPERKNIT_PRODUCT, CITRA_SUPERKNIT_PRODUCT],
    categories: [],
    description: '',
  },
];

export const mockCatalogResponse: CatalogResponse = {
  initial_products: [
    GEN4_DEVICE,
    ENGRAVING_PRODUCT,
    ...mockSuperknitProducts,
    WYW_PRODUCT,
  ],
  memberships: [ANNUAL_MEMBERSHIP, MONTHLY_MEMBERSHIP, TRIAL_MEMBERSHIP],
  accessories: [
    mockSuperknitAccessoryCategory,
    mockBatteryAccessoryCategory,
    mockStarterPackAccessoryCategory,
    mockWywCategory,
  ],
};

export const mockCatalogResponseWithDiscount: CatalogResponse = {
  initial_products: [
    GEN4_DEVICE,
    ENGRAVING_PRODUCT,
    ...mockSuperknitProducts,
    WYW_PRODUCT,
  ],
  memberships: [
    DISCOUNTED_ANNUAL_MEMBERSHIP,
    MONTHLY_MEMBERSHIP,
    TRIAL_MEMBERSHIP,
  ],
  accessories: [
    mockSuperknitAccessoryCategory,
    mockBatteryAccessoryCategory,
    mockStarterPackAccessoryCategory,
    mockWywCategory,
  ],
};

export const JOIN_MEMBERSHIPS = [
  TRIAL_MEMBERSHIP,
  ANNUAL_MEMBERSHIP,
  TWO_YEAR_MEMBERSHIP,
];
