import type {
  Language,
  Region,
  JFCurrency,
} from '@whoop/i18n/types/internationalization';
import {
  DEFAULT_CURRENCY,
  DEFAULT_LANGUAGE,
  DEFAULT_REGION,
} from '@whoop/i18n/types/internationalization';
import type { CountryCode } from '@whoop/i18n/types/address';
import { DEFAULT_COUNTRY_CODE } from '@whoop/i18n/types/address';
import type { StoreApi } from 'zustand';
import { createStore } from 'zustand';
import type { Address, PromoCodeState, PromoInfo } from 'ui';
import type { JoinFlowContent } from 'services/generated/growth-content-service';
import type { CheckoutPromo } from 'services/generated/membership-service-v2';
import { immer } from 'zustand/middleware/immer';
import type {
  ReceiptOrderStatusResponse,
  CatalogResponse,
  CheckoutResponse,
  GiftDetails,
} from 'services/generated/commerce-service';
import type { ParsedCartDTO } from 'ui/types/cartTypes';
import type { AddressVerificationStatus } from 'ui/types/Address';
import type { EngravingInfo } from 'ui/utils/engravingUtils';
import type { StripePaymentRequest } from 'ui/types/Stripe';
import type { SiteWidePromoContent } from '@/types/siteWidePromoTypes';
import type { PromoType } from '@/lib/utils/initialDataFetcher';
import { adjustToJFCurrency } from '@/lib/utils/currencyHelper';

interface ISiteProps {
  ecommCatalog: CatalogResponse;
  asAGift: boolean;
  language: Language;
  region: Region;
  country: CountryCode;
  currency: JFCurrency;
  cartExceedsBatteryLimit: boolean;
  shippingAddress?: Address;
  promoInfo?: PromoInfo;
  promoCode?: PromoCodeState;
  email?: string;
  isCartOpen: boolean;
  giftMessaging?: GiftDetails;
  // Once fully implemented, remove promoInfo and promoCode and replace everywhere in app with promoContent usage
  // Then this object should no longer be optional
  promoContent?: JoinFlowContent;
  featureFlags: Record<string, boolean>;
  experimentVariants: Record<string, string>;
  returnShippingCost?: number;
  siteWidePromoContent?: SiteWidePromoContent;
  isRafGiftFlow?: boolean;
  // non promo site wide banner text
  siteBannerText?: string;
  cart: ParsedCartDTO;
  checkoutResponse?: CheckoutResponse | ReceiptOrderStatusResponse;
  promoType: PromoType;
  stripePaymentRequest?: StripePaymentRequest;
  addressVerificationStatus: AddressVerificationStatus;
  engravingInfo?: EngravingInfo;
  isSkipMemSelectFlow?: boolean;
}

// Site Actions
interface ISiteActions {
  toggleAsAGift: () => void;
  setAsAGift: (isGift: boolean) => void;
  initializeFeatureFlags: (featureFlags: Record<string, boolean>) => void;
  initializePromo: (
    promoContent?: JoinFlowContent,
    promo?: CheckoutPromo,
    siteWidePromoContent?: SiteWidePromoContent,
    isRafGiftFlow?: boolean,
  ) => void;
  setShippingAddress: (address: Address) => void;
  setCartExceedsBatteryLimit: (boolean) => void;
  setPromoCode: (promoCode: PromoCodeState) => void;
  setEmail: (email: string | undefined) => void;
  setEcommCatalog: (catalog: CatalogResponse) => void;
  setReturnShippingCost: (returnShippingCost?: number) => void;
  toggleIsCartOpen: () => void;
  setIsCartOpen: (isCartOpen: boolean) => void;
  setGiftMessaging: (giftMessage: GiftDetails | undefined) => void;
  setExperimentVariant: (experimentName: string, variant: string) => void;
  setIsRafGiftFlow: (isRafGiftFlow: boolean) => void;
  // non promo site wide banner text
  setSiteBannerText: (bannerText: string | undefined) => void;
  setEcommCart: (cart: ParsedCartDTO) => void;
  setCheckoutResponse: (
    checkoutResponse: CheckoutResponse | ReceiptOrderStatusResponse,
  ) => void;
  setPromoType: (promoType: PromoType) => void;
  setStripePaymentRequest: (stripePaymentRequest: StripePaymentRequest) => void;
  setAddressVerificationStatus: (status: AddressVerificationStatus) => void;
  setEngravingInfo: (engravingInfo: EngravingInfo) => void;
  setIsSkipMemSelectFlow: (boolean) => void;
}

export interface ISiteStore extends ISiteProps, ISiteActions {}

export const DEFAULT_PROPS: ISiteProps = {
  ecommCatalog: { initial_products: [], memberships: [], accessories: [] },

  asAGift: false,
  language: DEFAULT_LANGUAGE,
  region: DEFAULT_REGION,
  country: DEFAULT_COUNTRY_CODE,
  currency: adjustToJFCurrency(DEFAULT_CURRENCY),
  cartExceedsBatteryLimit: false,
  isCartOpen: false,
  featureFlags: {},
  experimentVariants: {},
  cart: {
    id: null,
    cartItems: [],
    billingDetails: [],
    shippingMethodItems: [],
  },
  siteBannerText: undefined,
  promoType: 'None',
  stripePaymentRequest: undefined,
  addressVerificationStatus: 'Default',
  engravingInfo: undefined,
};

export const createSiteStore = (
  initState: ISiteProps = DEFAULT_PROPS,
): StoreApi<ISiteStore> => {
  return createStore<ISiteStore>()(
    immer((set) => ({
      ...initState,
      toggleAsAGift: () =>
        set((state) => ({
          asAGift: !state.asAGift,
        })),
      setAsAGift: (isGift: boolean) =>
        set(() => ({
          asAGift: isGift,
        })),
      setEcommCatalog: (catalog: CatalogResponse) => {
        set((state) => {
          state.ecommCatalog = catalog;
        });
      },
      setReturnShippingCost: (returnShippingCost?: number) => {
        set((state) => {
          state.returnShippingCost = returnShippingCost;
        });
      },
      initializeFeatureFlags: (featureFlags: Record<string, boolean>) => {
        set((state) => {
          state.featureFlags = featureFlags;
        });
      },
      initializePromo: (
        promoContent?: JoinFlowContent,
        promo?: CheckoutPromo,
        siteWidePromoContent?: SiteWidePromoContent,
        isRafGiftFlow?: boolean,
      ) => {
        set((state) => {
          // Future TODO : use this promo code everywhere instead of always doing this logic
          // https://whoopinc.atlassian.net/browse/GRO-3001
          state.promoCode =
            promoContent?.partnership_name || isRafGiftFlow
              ? promoContent?.promo_info
              : promo?.promo_code;
          state.promoContent = promoContent;
          state.promoInfo = promo;
          state.siteWidePromoContent = siteWidePromoContent;
        });
      },
      setShippingAddress: (address: Address) => {
        set((state) => {
          state.shippingAddress = address;
        });
      },
      setCartExceedsBatteryLimit: (hascartExceedsBatteryLimit: boolean) =>
        set((state) => {
          state.cartExceedsBatteryLimit = hascartExceedsBatteryLimit;
        }),
      setPromoCode: (promoCode: PromoCodeState) => {
        set((state) => {
          state.promoCode = promoCode;
        });
      },
      setEmail: (email: string | undefined) => {
        set((state) => {
          state.email = email;
        });
      },
      toggleIsCartOpen: () =>
        set((state) => {
          state.isCartOpen = !state.isCartOpen;
        }),
      setIsCartOpen: (isCartOpen: boolean) => {
        set((state) => {
          state.isCartOpen = isCartOpen;
        });
      },
      setGiftMessaging: (giftMessage: GiftDetails | undefined) => {
        set((state) => {
          state.giftMessaging = giftMessage;
        });
      },
      setExperimentVariant: (experimentName: string, variant: string) => {
        set((state) => {
          state.experimentVariants = {
            ...state.experimentVariants,
            [experimentName]: variant,
          };
        });
      },
      setIsRafGiftFlow: (isRafGift: boolean) => {
        set((state) => {
          state.isRafGiftFlow = isRafGift;
        });
      },
      setSiteBannerText: (bannerText: string | undefined) => {
        set((state) => {
          state.siteBannerText = bannerText;
        });
      },
      setEcommCart: (cart: ParsedCartDTO) => {
        set((state) => {
          state.cart = cart;
        });
      },
      setCheckoutResponse: (
        checkoutResponse: CheckoutResponse | ReceiptOrderStatusResponse,
      ) => {
        set((state) => {
          state.checkoutResponse = checkoutResponse;
        });
      },
      setPromoType: (promoType: PromoType) => {
        set((state) => {
          state.promoType = promoType;
        });
      },
      setStripePaymentRequest: (stripePaymentRequest: StripePaymentRequest) => {
        set((state) => {
          state.stripePaymentRequest = stripePaymentRequest;
        });
      },
      setAddressVerificationStatus: (status: AddressVerificationStatus) => {
        set((state) => {
          state.addressVerificationStatus = status;
        });
      },
      setEngravingInfo: (engravingInfo: EngravingInfo) => {
        set((state) => {
          state.engravingInfo = engravingInfo;
        });
      },
      setIsSkipMemSelectFlow: (skip: boolean) => {
        set((state) => {
          state.isSkipMemSelectFlow = skip;
        });
      },
    })),
  );
};
